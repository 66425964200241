import "./App.css";
import gif from "./rename2.gif";
import gif2 from "./Logo.png";
import { useEffect, useState } from "react";
import twitter from "./twitter (2).png";
import discord from "./discord (8).png";
function App() {
  return (
    <div className="bkg">
      <div className="mintContainer">
        <div>
          {/* {!load ? (
            <>
              <img className="gif" src={gif} alt=""></img>
            </>
          ) : (
            <>
              <img className="gif logo" src={gif2} alt=""></img>
            </>
          )} */}
        </div>

        <div className="center">
          <div className="title">HIGH VOLTAGE WORLDS</div>
          <div className="text">
            The first Solana NFT paying holders Bitcoin mining profits.
            <br></br>5 families, 1 community. Our utility, your world.
          </div>

          <div className="links">
            <a href="https://discord.gg/hvworldsnft">
              <div className="">
                <button className="twitter">DISCORD</button>
              </div>
            </a>
            <div className="">
              <button className="twitter">Mint date TBA!</button>
            </div>
            <a href="https://twitter.com/hvWorldsNFT">
              <div>
                <button className="discord">TWITTER</button>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
